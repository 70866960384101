import React from "react";
import { useTheme, Theme, Container, Box, Typography, Button } from "@material-ui/core";
import { useStaticQuery, graphql, Link } from "gatsby";
import styled from "@emotion/styled";
import Section from "../../components/section";
import Hero from "../../components/hero";
import SEO from "../../components/seo";
import MainLayout from "../../layouts/mainLayout";
import { RoundPaper } from "../../components/styledComponents";
import ContactForm from "../../components/contact-form";

interface LocationProps {
    location: Location;
}

const NegMargnSection = styled(Section)((props: { theme: Theme }) => `
    margin-top: -185px;
    z-index: 2;
    position:relative;

    ${props.theme.breakpoints.down("sm")} {
        margin-top: -125px;
    }
`);

const NeuroAcademyOrganizationsPage: React.FC<LocationProps> = (props) => {
    const theme = useTheme<Theme>();
    const data = useStaticQuery(graphql`
    query {
        indv: individualPackagesJson(block: {eq: "hero"}) {
            image {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 4160) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
        org: organizationPackagesJson(block: {eq: "hero"}) {
            image {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 4160) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    }
    `);

    const urlParams = new URLSearchParams(props.location.search);

    let img = data.org.image;
    let type = "organization";
    const pkg = urlParams.get('pkg') || 'unknown';

    if(urlParams.has("individual")) {
        img = data.indv.image;
        type = "individual";
    }

    let interest = `I am interested in the ${type} ${pkg} package.`;

    return (<MainLayout transparentNav>
        <SEO title="Course Registration" location={props.location} />
        <Hero heroHeight={50} title={"Registration Coming Soon"} overlayColor={theme.palette.primary.dark} image={img} />
        <NegMargnSection theme={theme}>
            <Container maxWidth="md">
                <RoundPaper elevation={6}>
                    <Box py={4}>
                        <ContactForm title="Let me know when registration opens." cta="Subscribe" message={interest} />
                    </Box>
                </RoundPaper>
            </Container>
        </NegMargnSection>

    </MainLayout>);
};

export default NeuroAcademyOrganizationsPage;